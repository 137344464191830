import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Button from 'components/common/Button';
import styles from './FreeShipBanner.module.scss';

const FreeShipBanner = ({ onSeePlansClick }) => {
  const router = useRouter();
  return (
    <div className={styles.freeShipBannerContainer}>
      <div className={styles.freeShipBannerMobileImg}>
        <img
          src="https://media.blueapron.com/assets/registration/homepage/fourth-july-free-ship-burger.webp?width=800&quality=90"
          alt="A box containing prepared and ready meals"
          loading="lazy"
        />
      </div>
      <div id="pnr-see-plans-hp-banner-cta" className={styles.copyContainer}>
        <h1 className={styles.copyHeading}>
          <span>Limited Time!</span>
        </h1>
        <p>
          <span className={styles.freeShipping}>Free Shipping</span>
          {` `} for 1 year when you order by 7/14 for delivery by 7/21. Terms Apply.
        </p>
        <Button
          color="white"
          size="small"
          buttonType="pill-filled"
          text="See Plans"
          classNames={styles.new}
          onClick={() => {
            onSeePlansClick();
            router.push('/pricing');
          }}
        />
      </div>
      <div className={styles.freeShipBannerImg}>
        <img
          src="https://media.blueapron.com/assets/registration/homepage/fourth-july-free-ship-burger.webp?width=800&quality=90"
          alt="A box containing prepared and ready meals"
          height="500"
          width="1369"
          loading="lazy"
        />
      </div>
    </div>
  );
};

FreeShipBanner.propTypes = {
  onSeePlansClick: PropTypes.func.isRequired
};

export default FreeShipBanner;
