import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { isCrossmenuEnabledForWeb } from 'redux/reducers/configurations';

import Button from 'components/common/Button';
import styles from './Banner.module.scss';

const Banner = ({ onSeePlansClick }) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const router = useRouter();
  const crossMenuEnabled = useSelector(isCrossmenuEnabledForWeb);
  const hasUserEverLoggedIn = Cookies.get('has_ever_logged_in');
  const heading = () => {
    if (!crossMenuEnabled) {
      return (
        <>
          <span>New! </span>
          Prepared & Ready
        </>
      );
    }
    if (hasUserEverLoggedIn) {
      return (
        <>
          <span>New! </span>
          More options. More savings.
        </>
      );
    }
    return <>Save up to $4 per serving</>;
  };

  const subHeading = () => {
    if (!crossMenuEnabled) {
      return (
        <p>
          Get pre-made meals delivered fresh with top-quality ingredients
          <span className={styles.pricing}>—now starting at just $9.99.</span>
        </p>
      );
    }
    if (hasUserEverLoggedIn) {
      return (
        <p>
          Enjoy unlimited menu access and
          <span className={styles.pricing}> save up to $4 per serving or Add-on </span>
          when you order more.
        </p>
      );
    }
    return (
      <p>
        Enjoy
        <span className={styles.pricing}> unlimited menu access </span>
        to 100+ weekly options. The more you order, the more you save!
      </p>
    );
  };

  const buttonText = () => {
    if (hasUserEverLoggedIn && crossMenuEnabled) {
      return 'Order Now';
    }
    return 'See Plans';
  };

  if (!hydrated) {
    return null;
  }

  return (
    <div className={styles.pnRBannerContainer}>
      <div className={styles.pnRBannerMobileImg}>
        <img
          src="https://media.blueapron.com/assets/registration/homepage/marble-variety-prepared-mobile.webp?width=800&quality=90"
          alt="A box containing prepared and ready meals"
          loading="lazy"
        />
      </div>
      <div id="pnr-see-plans-hp-banner-cta" className={styles.copyContainer}>
        <h1 className={styles.copyHeading}>{heading()}</h1>
        {subHeading()}
        <Button
          color="white"
          size="small"
          buttonType="pill-filled"
          text={buttonText()}
          classNames={styles.new}
          onClick={() => {
            onSeePlansClick('pnr-banner-see-plans');
            router.push('/pricing?plan=pnr');
          }}
        />
      </div>
      <div className={styles.pnRBannerImg}>
        <img
          src="https://media.blueapron.com/assets/registration/homepage/marble-variety-prepared.webp?height=500&quality=90"
          alt="A box containing prepared and ready meals"
          height="500"
          width="1369"
          loading="lazy"
        />
      </div>
    </div>
  );
};

Banner.propTypes = {
  onSeePlansClick: PropTypes.func.isRequired
};

export default Banner;
