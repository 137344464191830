import React from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { browseMenuClick, weeklyOptionsTileClick } from 'shared/utils/tracking-utils/homepage';
import { mealTiles } from '../constants';
import styles from './WeeklyOptions.module.scss';

const WeeklyOptions = () => (
  <section className={styles.weeklyOptionsSection}>
    <h2 className={styles.sectionMainTitle}>Choose from</h2>
    <h2 className={styles.sectionSubTitle}>80+ weekly options</h2>
    <div className={styles.weeklyPhotoSection}>
      {mealTiles.map((meal) => (
        <div key={meal.title}>
          <div className={styles.link}>
            <Link className={styles.link} href="/on-the-menu">
              <img
                src={meal.image}
                alt={meal.alt}
                className="recipe-image"
                loading="lazy"
                onClick={() => weeklyOptionsTileClick(meal.title)}
                onKeyDown={() => weeklyOptionsTileClick(meal.title)}
                role="link"
                height="300"
                width="300"
              />
            </Link>
          </div>
          <p className={styles.title}>{meal.title}</p>
          <p className={styles.subTitle}>{meal.subtitle}</p>
        </div>
      ))}
    </div>
    <a
      className={cx('btn', styles.browseMenusBtn)}
      onClick={browseMenuClick}
      id="browse-menus-btn"
      href="/pages/sample-recipes"
    >
      Browse Our Menus
    </a>
  </section>
);

export default WeeklyOptions;
