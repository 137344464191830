import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HomepageHeaderContainer from 'components/homepage/HomepageHeaderContainer';
import OptimizelyExperimentsContainer from 'components/common/OptimizelyExperimentsContainer';
import ErrorBoundary from 'components/common/ErrorBoundary';
import NoticesContainer from 'components/common/NoticesContainer';
import Footer from 'components/common/Footer';
import { StayConnected } from 'components/common/StayConnected';
import PreppedAndReadyMiniPlanSelectorContainer from 'components/common/planSelectors/PreppedAndReadyMiniPlanSelector/PreppedAndReadyMiniPlanSelectorContainer';
import { ToastContainerWrapper } from 'components/common/Toast';
import { initZendeskEffect, useShowZendeskChatEffect, useFlashMessageEffect } from 'components/common/hooks';
import { showError, showSuccess, DISPLAY_METHODS } from 'redux/reducers/notices';
import { hasFeature } from 'redux/reducers/configurations';
import { trackNewsletterSignupSubmit, trackNewsletterSignupEngage } from 'shared/utils/tracking-utils/homepage';
import { newsletterSubscribe } from 'services/api/newsletter';
import getUserFromCookie from 'shared/utils/cookie/user';
import cx from 'classnames';
import { TESTS_ENABLED, FREE_SHIP_CODES } from 'shared/constants/constants';
import { getCouponToBeApplied } from 'shared/utils/coupons';

import styles from './HomeApp.module.scss';
import HomepageHero from '../HomepageHero';
import HomepageSection from '../HomepageSection';
import WeeklyOptions from '../WeeklyOptions';
import TestimonialSection from '../TestimonialSection';
import GetStarted from '../GetStarted';
import BannerContainer from '../Banner/BannerContainer';
import FreeShipBanner from '../FreeShipBanner/FreeShipBannerContainer';
import ShortRatingsBanner from '../ShortRatingsBanner';

export const HomepageApp = () => {
  const isZendeskLoggedOutEnabled = useSelector((state) => hasFeature(state, TESTS_ENABLED.ZENDESK_LOGGED_OUT_ENABLED));
  const [isCouponPresent, setIsCouponPresent] = useState(false);
  const dispatch = useDispatch();
  const [signupClicked, setSignupClicked] = useState(false);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  initZendeskEffect();
  useShowZendeskChatEffect(isZendeskLoggedOutEnabled);
  useFlashMessageEffect();
  const planSelectorEnabled = useSelector((state) =>
    hasFeature(state, 'DEPT Test 11: Mini Plan Selector on the Homepage Variation #1')
  );
  const currentCoupon = useSelector((state) => getCouponToBeApplied(state.coupons));

  useEffect(() => {
    const user = getUserFromCookie();
    const isActiveUser = user && user.id && user.is_active;
    if (isActiveUser) {
      window.location = '/account';
      setShowNewsletterForm(false);
    } else {
      setShowNewsletterForm(true);
    }
  }, []);

  const handleNewsletterInput = (form) => {
    if (!signupClicked && form?.email?.value !== '') {
      setSignupClicked(true);
      trackNewsletterSignupEngage();
    }
  };

  const handleNewsletterSubmit = async ({ email }) => {
    trackNewsletterSignupSubmit();
    try {
      await newsletterSubscribe({
        newsletter_subscriber: {
          source: 'Footer newsletter form',
          receive_meat_fish: 1,
          email
        }
      });

      dispatch(
        showSuccess(
          'newlsetter_subscribe_success',
          `${email} has been subscribed to the recipe newsletter!`,
          DISPLAY_METHODS.FLASH
        )
      );
    } catch (e) {
      dispatch(
        showError(
          'newsletter_subscribe_failure',
          e.message || 'Could not subscribe, please try agagin later',
          DISPLAY_METHODS.FLASH
        )
      );
    }
  };

  const currentCouponIsFreeShippingCoupon = currentCoupon && FREE_SHIP_CODES.includes(currentCoupon.code);

  return (
    <div className={cx(styles.container, { [styles.firefoxOverflowFix]: !isCouponPresent })}>
      <ErrorBoundary>
        <OptimizelyExperimentsContainer />
      </ErrorBoundary>
      <ToastContainerWrapper />
      <HomepageHeaderContainer setIsCouponPresent={setIsCouponPresent} />
      <NoticesContainer isSticky={false} shouldScroll={false} />
      <HomepageHero />
      {currentCouponIsFreeShippingCoupon ? <FreeShipBanner /> : <BannerContainer />}
      <ShortRatingsBanner />
      {planSelectorEnabled && (
        <div className={styles.planSelectorWrapper}>
          <PreppedAndReadyMiniPlanSelectorContainer />
        </div>
      )}
      <WeeklyOptions />
      <HomepageSection />
      <TestimonialSection />
      <GetStarted />
      <StayConnected
        onSubmit={handleNewsletterSubmit}
        onInputChange={handleNewsletterInput}
        showNewsletterSignup={showNewsletterForm}
      />
      <Footer />
    </div>
  );
};

export default HomepageApp;
