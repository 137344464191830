import React, { useState, useEffect, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Header from 'components/common/Header';
import CouponBanner from 'components/common/CouponBanner';
import CouponModal from 'components/registration/CouponModal';

import { removeNotice } from 'redux/reducers/notices';
import { hasFeature } from 'redux/reducers/configurations';
import { trackPageView } from 'shared/utils/tracking-utils/homepage';
import { fetchCoupon } from 'redux/reducers/coupons';
import { getCouponToBeApplied, isGiftCoupon } from 'shared/utils/coupons';
import { PROMO_BANNER_EXPERIMENT } from 'shared/constants/constants';
import HomepageCouponBanner from './HomepageCouponBanner';
import styles from './HomepageHeaderContainer.module.scss';

const HOMEPAGE_COUPON_NOTICE = 'homepage-coupon-banner';

const HomepageHeaderContainer = ({
  reduxFetchCoupon,
  reduxRemoveNotice,
  coupon,
  hasAppliedCoupon,
  setIsCouponPresent
}) => {
  const isCouponVariant = useSelector((state) => hasFeature(state, PROMO_BANNER_EXPERIMENT.VARIATION));
  const [showCouponModal, setShowCouponModal] = useState(false);
  const currentCouponCode = coupon && coupon.code;

  useEffect(() => {
    const handleCoupon = async () => {
      await reduxFetchCoupon();

      if (coupon) {
        const { discount, maxAmountPerUse } = coupon;
        if (!isGiftCoupon(coupon)) {
          reduxRemoveNotice(HOMEPAGE_COUPON_NOTICE);
        }

        setIsCouponPresent(true);
        trackPageView({ discount, maxAmountPerUse });
      } else {
        setIsCouponPresent(false);
        trackPageView();
      }
    };
    handleCoupon();

    return () => {
      reduxRemoveNotice(HOMEPAGE_COUPON_NOTICE);
    };
  }, [currentCouponCode]);

  if (coupon && !isGiftCoupon(coupon)) {
    reduxRemoveNotice(HOMEPAGE_COUPON_NOTICE);
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isCouponVariant ? (
        <Fragment id="homepage-header-container">
          {coupon && (
            <CouponBanner setShowCouponModal={setShowCouponModal} coupon={coupon} isApplied={hasAppliedCoupon} />
          )}
          <Header isSticky couponPresent={!!coupon} />
          {showCouponModal && <CouponModal setShowCouponModal={setShowCouponModal} coupon={coupon} learnMore />}
        </Fragment>
      ) : (
        <div className={styles.container} id="homepage-header-container">
          <Header isSticky />
          {coupon && <HomepageCouponBanner coupon={coupon} setShowCouponModal={setShowCouponModal} />}
          {showCouponModal && <CouponModal setShowCouponModal={setShowCouponModal} coupon={coupon} learnMore />}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  reduxRemoveNotice: removeNotice,
  reduxFetchCoupon: fetchCoupon
};

const mapStateToProps = (state) => ({
  coupon: getCouponToBeApplied(state.coupons)
});

HomepageHeaderContainer.propTypes = {
  hasAppliedCoupon: PropTypes.bool,
  reduxRemoveNotice: PropTypes.func.isRequired,
  reduxFetchCoupon: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    couponMessage: PropTypes.string.isRequired,
    maxAmountPerUse: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        planId: PropTypes.string.isRequired,
        productsPerOrder: PropTypes.string.isRequired,
        introductory: PropTypes.arrayOf(PropTypes.string).isRequired
      })
    ),
    restrictions: PropTypes.shape({
      card: PropTypes.bool
    })
  }),
  setIsCouponPresent: PropTypes.func.isRequired
};

HomepageHeaderContainer.defaultProps = {
  coupon: null,
  hasAppliedCoupon: false
};

export { HomepageHeaderContainer as Component };

export default connect(mapStateToProps, mapDispatchToProps)(HomepageHeaderContainer);
