import React from 'react';

import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack } from 'shared/utils/segment/utils';
import FreeShipBanner from './FreeShipBanner';

const FreeShipBannerContainer = () => {
  const handleSeePlansClick = () => {
    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'link',
        text: 'See Plans',
        destination: '/pricing',
        id: 'free-ship-banner-see-plans'
      }
    });
  };

  return <FreeShipBanner onSeePlansClick={handleSeePlansClick} />;
};

export default FreeShipBannerContainer;
